html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2vw;

  @include media-breakpoint-down(lg) {
    font-size: 14px;
  }
}

@media screen and (min-width: 1600px) {
  html {
    font-size: 20px;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@include media-breakpoint-down(lg){
  body.navigation-open{
    overflow: hidden;
  }
}

// handle color changes

html{
  transition: background-color 700ms ease, color 700ms ease;
}

a:hover, a:focus {
  color: inherit;
}

p {
  color: inherit;
}

a {
  color: inherit;
}

h1 {
  color: inherit;
}

h2 {
  color: inherit;
}

div {
  color: inherit;
}

.fillColor {
  fill: currentColor;
}


.button-blank, .button-blank:focus, .button-blank:hover, .button-blank:active, .button-blank:visited, .button-blank:focus-within{
  background: none;
  outline: none;
  color: inherit;
  border: none;
  padding: 0;
}