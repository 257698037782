$transition-bezier: cubic-bezier(0.720, 0.170, 0.405, 0.935);

.container {
  //transition: max-width 500ms ease-out;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-out;
}

.fade-1000-enter {
  opacity: 0;
}

.fade-1000-enter-active {
  opacity: 1;
  transition: opacity 1000ms linear;
}

.fade-1000-exit {
  opacity: 1;
}

.fade-1000-exit-active {
  opacity: 0;
  transition: opacity 1000ms linear;
}


.fade-300-enter {
  opacity: 0;
}

.fade-300-enter-active {
  opacity: 1;
  transition: opacity 300ms linear;
}

.fade-300-exit {
  opacity: 1;
}

.fade-300-exit-active {
  opacity: 0;
  transition: opacity 300ms linear;
}


.fade-appear {
  opacity: 0;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 500ms ease-out;
}

.fade-long-enter {
  opacity: 0;
}

.fade-long-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-out;
}

.fade-long-exit {
  opacity: 1;
}

.fade-long-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-out;
}

.fade-long-appear {
  opacity: 0;
}

.fade-long-appear.fade-long-appear-active {
  opacity: 1;
  transition: opacity 1000ms ease-out;
}


.button-hover-scale {
  &:hover {
    transform: scale(1.1);
  }
}
