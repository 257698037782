.kuenstler-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: center;
  .letter-section {
    width: 100%;
  }
  .keunstler-land{
    color: #c6c6c6;
    margin-top: -.1em;
  }
  .keunstler-bio{
    margin-bottom: 1em;
  }
  a:hover{
    text-decoration: underline;
  }
  .kuenstler-item {
    user-select: none;
    text-align: center;
    width: 100%;
    flex-basis: 50%;
    margin: 3rem 0;
    @include media-breakpoint-down(sm) {
      flex-basis: 100%;
    }
  }
}
.kuenstlerIn-Letter{
  margin: 3rem 0;
}
