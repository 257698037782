// CUSTOM COLOR
$gray_wert: #f3f3f3 !default;

// variables for font-family
$font-family-base: neue-haas-grotesk-text, sans-serif;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bolder:          500 !default;

// body colors
$body-bg:                   none;
$body-color:                none;