// general navigation styles

.Navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  transition: height 250ms ease,  background-color 700ms ease, color 700ms ease;
  //border-bottom: 1px solid;
  user-select: none;
}

.Logo {
  position: absolute;
  left: 0;
  padding: .5rem;
  letter-spacing: .12em;
}

.NavigationLinks-Wrapper{
  background-color: none;

}
.NavigationLinks{
  align-items: center;
}

.NavigationLinks .svg-icon-wrapper{
  height: 1.2em;
  svg{
    display: block;
  }
}



// desktop navigation styles

@include media-breakpoint-up(lg) {

  .Navigation {
    height: 2.5rem;
  }

  .Navigation.show {
    height: 8rem;
  }

  .NavigationLinks-Wrapper {
    padding: 3rem .5rem;
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  .NavigationLinks {
    display: flex;
    justify-content: center;
  }

  .NavigationLinks a {
    margin: 0 1.5rem;
  }

  .Logo {
    bottom: 0;
  }

  .Navigation-Toggler {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding-right: .5rem;
    padding-top: .25rem;
  }

  .Toggle-Button {
    width: 2rem;
    height: 2rem;
    margin-left: 1rem;
  }

}


// mobile navigation styles

@include media-breakpoint-down(lg) {

  .Navigation {
    height: 2.5rem;
  }

  .Navigation.show {
    height: 100vh;
  }

  .NavigationLinks-Wrapper {
    position: absolute;
    height: 100vh;
    width: 100%;
    bottom: 0;
    opacity: 0;
    transition: opacity 700ms ease;
  }

  .Navigation.show .NavigationLinks-Wrapper {
    opacity: 1;
  }

  .NavigationLinks {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .NavigationLinks a {
    margin: 1.5rem 0;
  }

  .Logo {
    top: 0;
  }

  .Navigation-Toggler {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding-right: .5rem;
    padding-top: .25rem;
  }

  .Toggle-Button {
    width: 2rem;
    height: 2rem;
    margin-left: 1rem;
  }

}