.Ausstellungen {
}


.Ausstellungen-SectionHeadline {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-transform: uppercase;
}

.Ausstellungen-Section {
  margin-bottom: -2rem;
  @include media-breakpoint-up(lg) {
    margin-bottom: -4rem;
  }
}

.ItemRow {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 $grid-gutter-width;
  @include media-breakpoint-up(lg) {
    margin: 0;
    padding: 0 4rem;
  }

  .AusstellungsItem {
    margin-bottom: 2rem;
    width: 100%;
    max-width: 100%;
    @include media-breakpoint-up(lg) {
      max-width: 50%;
      padding: 0 4rem;
      margin-bottom: 4rem;
      width: auto;
    }
  }
}