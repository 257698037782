.AusstellungView {
}

.AusstellungCoverImage .Image {
  height: 80vh;
  max-height: calc( 100vh - 5.5rem );
  width: auto;
  display: block;
  margin: auto;
  object-fit: contain;
  max-width: 100%;
}

.AusstellungCoverImage.isPortrait .Image {
  height: 80vh;
  max-height: calc( 100vh - 5.5rem );
  width: auto;
}

@media (max-aspect-ratio: 1/1) {
  .AusstellungCoverImage .Image,
  .AusstellungCoverImage.isPortrait .Image {
    width: 100%;
    height: auto;
  }
}

.AusstellungTermine-Grid {
  margin-bottom: -2rem;
}

.AusstellungTermin {
  margin-bottom: 2rem;
}
