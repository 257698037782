.PhotosView {

  .lightbox {
    transition-delay: 200ms;
    transition: background-color 300ms ease-in-out;
    cursor: pointer;
  }
  .lightbox-image-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: white;
    display: grid;
    place-items: center;
    overflow-y: hidden;
    img {
      animation: appear 300ms ease-in-out;
      max-width: 93vw;
      max-height: 93vh;
      object-fit: contain;
    }
  }
  @keyframes appear {
    from {
      opacity: 0;
      margin-left: -100px;
    }
    to {
      opacity: 1;
      margin-left: 0;
    }
  }

  .Image {
    width: 100%;
    transition: all 500ms linear;
  }
}
