.Image{
  transition: opacity 300ms linear;
  user-select: none;
}
img.loading{
  opacity: 0;
}
img.loaded{
  opacity: 1;
}

//.image-wrapper{
//  img {
//    max-height: 90vh;
//    object-fit: contain;
//  }
//}

.ratio-wrapper{
  position: relative;
  width: 100%;
  //background-color: #f0f0f0;
}
.ratio-wrapper-inner{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}
