.page-content {
  position: relative;
  min-height: 100vh;
}

.page-block {
  margin-top: 8rem;
  margin-bottom: 8rem;
  &.first{
    margin-top: 2.5rem;
  }
}

.page-block-s {
  margin-top: 4rem;
  margin-bottom: 4rem;
  &.first{
    margin-top: 2.5rem;
  }
}

.page-block-xs {
  margin-top: 1rem;
  margin-bottom: 1rem;
}