.AusstellungsItem {

  transition: transform 500ms ease;
  user-select: none;

  &.ausstellung:hover{
    transform: scale(1.02);
  }
  &.teaser{
    color: #9f9f9f;
  }

  @include media-breakpoint-up(lg) {

    display: flex;
    flex-direction: column;
    justify-content: center;

    &.order-reverse {
      flex-direction: column-reverse;
      justify-content: center;

    }
  }

}


.AusstellungsText {
  margin-bottom: 1rem;

  p {
    margin: 0;
  }

  h2 {
    margin: 0;
  }

  h3 {
    margin: 0;
  }
}






.AusstellungItemBild {

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;

  &.fit-height{
    width: 25rem;
    height: auto;
  }
  &.fit-width{
    width: 35rem;
    height: auto;
  }

  &.small {
    &.fit-height{
      width: 13rem;
      height: auto;
    }
    &.fit-width{
      width: 20rem;
      height: auto;
    }
  }


  .Image {
    width: 100%;
    height: auto;
  }

}

.AusstellungsText.onlyText{
  margin: 0;
}