.Intro{
  display: flex;
  position: fixed;
  background: #ffffff;
  z-index: 20000;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  color: #000000 !important;
  text-transform: uppercase;
  letter-spacing: .12em;
}

.Intro-Logo{
  position: fixed;
  z-index: 20001;
  top: 50%;
  transform: translateY(-100%);
  left: 0;
  right: 0;
  text-align: center;
}