.GalerieView{
}

.GalerieImages{
  display: flex;
  justify-content: center;

  .Image{
    width: 50%;
    height: 100%;
  }

}