// IMPORTING GLOBAL SCSS
@import "_variables";
@import "node_modules/bootstrap/scss/bootstrap";
@import "_settings";
@import "_transitions";
@import "_typography";
@import "_layout";

// Swiper Module
@import 'swiper/swiper.scss';
@import 'swiper/components/navigation/navigation.scss';
@import 'swiper/components/pagination/pagination.scss';
@import 'swiper/components/scrollbar/scrollbar.scss';

// IMPORTING THE COMPONENT SCSS
@import "../components/AusstellungsView/AusstellungView";
@import "src/components/AusstellungsView/AusstellungCarousel";
@import "src/components/AusstellungsuebersichtView/AusstellungsuebersichtView";
@import "src/components/AussteullungsItem/AusstellungsItem";
@import "../components/PhotosView/Photos";
@import "src/components/Image/Image";
@import "src/components/Icons/Icons";
@import "src/components/Intro/Intro";
@import "src/components/Loader/Loader";
@import "src/components/KuenstlerInnenView/KuenstlerInnenView";
@import "src/components/PageContainer/PageContainer";
@import "src/components/PageHeader/PageHeader";
@import "../components/GalerieView/GalerieView";
@import "src/components/footer/footer";
@import "../components/navigation/Navigation";
