.AusstellungCarousel {
  .swiper-slide{
    width: auto;
  }
  .Slide{
    width: auto;
    height: 100%;

    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
    padding-left: $grid-gutter-width;
  }
  .Slide.last{
    padding-right: $grid-gutter-width;
  }
  .Slid-Caption{
    padding-top: .5rem;
    max-width: 80vw;
  }
  img{
    height: 800px;
    height: 50vh;
    height: 50vmin;
    display: block;
    width: auto;
  }
}