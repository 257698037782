/*

# Neue Haas Unica W1G Bold
  font-family: neue-haas-unica, sans-serif;
  font-weight: 600;
  font-style: normal;

# Neue Haas Unica W1G Bold Italic
  font-family: neue-haas-unica, sans-serif;
  font-weight: 600;
  font-style: italic;

# Neue Haas Unica W1G Italic
  font-family: neue-haas-unica, sans-serif;
  font-weight: 400;
  font-style: italic;

# Neue Haas Unica W1G Light
  font-family: neue-haas-unica, sans-serif;
  font-weight: 300;
  font-style: normal;

# Neue Haas Unica W1G Light Italic
  font-family: neue-haas-unica, sans-serif;
  font-weight: 300;
  font-style: italic;

# Neue Haas Unica W1G Medium
  font-family: neue-haas-unica, sans-serif;
  font-weight: 500;
  font-style: normal;

# Neue Haas Unica W1G Medium Italic
  font-family: neue-haas-unica, sans-serif;
  font-weight: 500;
  font-style: italic;

# Neue Haas Unica W1G Regular
  font-family: neue-haas-unica, sans-serif;
  font-weight: 400;
  font-style: normal;

*/

body {
  font-family: neue-haas-unica, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.text-standard {
  font-family: neue-haas-unica, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.text-medium {
  font-family: neue-haas-unica, sans-serif;
  font-weight: 500;
  font-style: normal;
}

.text-bold {
  font-family: neue-haas-unica, sans-serif;
  font-weight: 500;
  font-style: normal;
}

.text-headline {
  font-family: neue-haas-unica, sans-serif;
  font-weight: 500;
  font-style: normal;
  letter-spacing: .1em;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.text-subheadline {
  font-family: neue-haas-unica, sans-serif;
  font-weight: 500;
  font-style: normal;
  letter-spacing: .1em;
  font-size: 1.25rem;
  text-transform: uppercase;
}

.text-tinyheadline {
  font-family: neue-haas-unica, sans-serif;
  font-weight: 500;
  font-style: normal;
  letter-spacing: .1em;
  font-size: 1rem;
  text-transform: uppercase;
}

.text-tiny {
  font-size: .8rem;
  letter-spacing: .12em;
  font-weight: 500;
  @include media-breakpoint-up(xl){
    font-size: .7rem;
  }
}

@media screen and (min-width: 1600px) {
  font-size: 13px;
}


strong {
  font-weight: 600;
}

.redactor-default {
}

.redactor-tiny {
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}
